import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Button from "../components/button"

class IndexPage extends React.Component {
  render() {
    const siteTitle = "here is my personal website"

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Home"
          keywords={[`blog`, `gatsby`, `javascript`, `react`]}
        />
        <h1>
          Hey people{" "}
          <span role="img" aria-label="wave emoji">
            👋
          </span>
        </h1>

        <p>
          {" "}
          this is a lame website but if you're here I guess check out my blog or
          my "social".
        </p>

        <a href="https://www.linkedin.com/in/ashwin-raja-07729b32?trk=profile-badge">
          LinkedIn profile
        </a>
        <br></br>
        <a href="https://www.instagram.com/yumyumfrenchie/">
          My dog's instagram
        </a>
        <p> </p>

        <p>
          {" "}
          I currently work at <a href="https://www.stripe.com">Stripe</a>.
          Previously <a href="https://www.iterable.com">Iterable</a> and{" "}
          <a href="https://www.workday.com">Workday</a>.
        </p>
        <Link to="/blog/">
          <Button marginTop="35px">Go to Blog</Button>
        </Link>
      </Layout>
    )
  }
}

export default IndexPage
